"use client";
import React, { useState, useEffect } from 'react';

export default function Home() {
    const [content, setContent] = useState('');

    const loadContent = () => {
        fetch('/api/content')
            .then(response => response.json())
            .then(data => {
                setContent(data.content);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        loadContent();
        // const interval = setInterval(loadContent, 5000); // 每5秒检查一次更新
        // return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <h1>显示页面</h1>
            <div id="display-content" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
}
